// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("stylesheets/application.scss")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

// Open all external links in a new window
addEventListener("click", function(event) {
  var el = event.target
  if (el.tagName === "A") {
  	if(el.host == window.location.host && el.href.includes("documents")) {
  		el.setAttribute("target", "_blank")
  	} else if(el.host != window.location.host) {
  		el.setAttribute("target", "_blank")
  	}
  } else if(el.tagName === "STRONG" && el.parentElement.tagName === "A") {
  	if(el.parentElement.host == window.location.host && el.parentElement.href.includes("documents")) {
  		el.parentElement.setAttribute("target", "_blank")
  	} else if(el.parentElement.host != window.location.host) {
  		el.parentElement.setAttribute("target", "_blank")
  	}
  }
}, true)